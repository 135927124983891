<template>
    <div style="width:100%;height:100%;">
        <div class="pagetitle-con">
            <div class="login-content">
                <!-- <img src="../assets/content/logo_s.png"> -->
                <p class="title" style="padding-bottom: 25px;">歡迎您使用</p>
                <!-- <p class="title" style="padding-bottom: 8px;"><sapm style="color:#fff;">123</sapm></p> -->
                <!-- <p class="title" style="padding-bottom: 8px;"><sapm style="color:#fff;">3D partners</sapm></p> -->
            </div>
        </div>
        <div class="login_s">
            <div class="grace-form">
                <p class="login-in-top" style="color: rgb(196, 155, 108);">首次登入/註冊賬號</p>
                <p class="login-in-title" >如果你已有我們的會員賬號或是訂閱小程序區域成員，請以你已有賬號登入。</p>
                <p class="email">
                    <input type="text" v-model="email" class="grace-form-inputs" placeholder="電郵">
                </p>
                <p class="email">
                    <input type="password" v-model="password" class="grace-form-inputs" placeholder="密碼">
                </p>
                <p class="forget" @click="forget">忘記密碼</p>
                <div class="btns">
                    <div>
                        <button class="button" @click="login">登入</button>
                    </div>
                    <div style="padding-left: 31px;">
                        <router-link :to="{path:'register'}">
                            <button class="button">註冊</button>
                        </router-link>
                    </div>
                </div>
                <p class="skip" @click="skip" style="color: rgb(196, 155, 108);">略過></p>
            </div>
        </div>
    </div>
</template>
<script>
import { apiLogin,apiUsersForget } from '@/request/api';
export default {
    data(){
        return {
            email:'',
            password:''
        }
    },
    methods:{
        forget(){
            let email  = this.email;
            let regEmail = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            if(!email){
                this.$message({
                    message: '邮箱不能爲空',
                    type: 'error'
                })
                return false;
            }
            if(!regEmail.test(email)) {
                this.$message({
                    message: '邮箱格式不正确',
                    type: 'error'
                })
                return false;
            }
            apiUsersForget({email:email}).then(res => {
                if(res.code != 200){
                    this.$message({
                        message: res.message,
                        type: 'error'
                    })
                }else{
                    this.$message({
                        message:'重置密碼已發送到您的郵箱,請注意查看',
                        type: 'success'
                    })
                }
            }).catch(error=>{
                console.log(error);
            })
        },
        skip(){
            location.href="/my";
        },
        login(){
            let email  = this.email;
            let password = this.password;
            let regEmail = /^\w[-\w.+]*@([A-Za-z0-9][-A-Za-z0-9]+\.)+[A-Za-z]{2,14}/;
            if(!email){
                this.$message({
                    message: '邮箱不能爲空',
                    type: 'error'
                })
                return false;
            }else if(!regEmail.test(email)) {
                this.$message({
                    message: '邮箱格式不正确',
                    type: 'error'
                })
                return false;
            }

            if(!password){
                this.$message({
                    message: '請填寫正確的密碼',
                    type: 'error'
                })
                return false;
            }

            let that = this;
            this.$axios.get('http://3d.partners/sanctum/csrf-cookie').then( async () => {
                apiLogin({
                    account:email,
                    password:this.$md5(password)
                }).then(res=>{
                    if(res.code == 200){
                        that.$message({
                            message: '登錄成功',
                            type: 'success'
                        })
                        // 将用户token保存到vuex中
                        var token = res.data.api_token;
                        window.localStorage.setItem("user_avatar", res.data.avatar);
                        window.localStorage.setItem("month_goal", res.data.month_goal);
                        window.localStorage.setItem("user_name", res.data.name);
                        window.localStorage.setItem("user_id", res.data.id);
                        window.localStorage.setItem("token", token);
                        window.localStorage.setItem("lang_id", res.data.lang_id);
                        that.$store.state.user_avatar = res.data.avatar;
                        that.$store.state.user_name = res.data.name;
                        that.$store.state.user_id = res.data.id;
                        that.$store.state.login_verify = token;
                        that.$store.state.lang_id = res.data.lang_id;
                        that.$store.state.month_goal = res.data.month_goal;
                        that.$router.push({path:'/'});
                    }else{
                        that.$message.error(res.message);
                    }
                }).catch(function (error) { // 请求失败处理
                    console.log(error);          
                    return '';
                });
            });
        }
    }
}
</script>

<style>
    a{
        text-decoration: none;
    }
  ::-webkit-input-placeholder{
    color:#ccc;
    font-size:15px;
  }
  .skip{
    position: fixed;
    right: 30px;
    bottom: 20px;
    font-size: 14px;
  }
  .button{
    background: rgb(196, 155, 108);
    width: 82px;
    border: none;
    border-radius: 0;
    color: #fff;
    font-size: 18px;
    position: relative;
    display: block;
    margin-left: auto;
    margin-right: auto;
    padding-left: 14px;
    padding-right: 14px;
    box-sizing: border-box;
    text-align: center;
    text-decoration: none;
    line-height: 2.55555556;
    cursor: pointer;
  }
  .btns{
    padding-top: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    width: 100%;
  }
  .forget{
    text-align: right;
    text-decoration: underline;
    color: rgb(196, 155, 108);
    font-size: 14px;
  }
.grace-form-inputs{
    text-align: left;
    width: 100%;
    height: 20px;
    outline: none;
    line-height: 20px;
    margin: 10px 0;
    background: none;
    border: 0;
    color: #333;
    font-size: 13px;
}
    .email{
        margin-top: 15px;
        padding: 0 10px;
        border: 2px solid #aaa;
    }
    .login-in-title{
        padding-top: 8px;
        padding-bottom: 15px;
        font-size: 14px;
        color: #838383;
        text-align: center;
    }
    .login-in-top{
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        font-size: 16px;
    }
    .grace-form{
        display: block;
        width: 100%;
        height: auto;
        overflow: hidden;
        margin-top: 20px;
    }
    .login_s{
        width:100%;
        height:81.5vh;
        background:#fff;
        padding: 20px 53px;
        width: unset;
    }
    p{
        margin:0;
        color:#fff;
    }
    .title{
        font-family: Helvetica Neue;
        font-weight: 400;
        font-size: 24px;
        text-align: left;
    }
    /* .pagetitle-con{
        background-color: #C49B6C;
        margin-top:-60px;
    } */
    .login-content img{
        height: 86px;   
        padding-top: 3px;
        width: 88px;
    }
    .login-content{
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        align-items: center;
    }
</style>